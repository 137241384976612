import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Navbar from "./Navbar";
import swal from 'sweetalert';
import { Transform } from "@mui/icons-material";

const Contact = () => {
  const [errMsg, setErrmsg] = useState([])

  const [inpval, setINP] = useState({
    name: "",
    email: "",
    mobileno: "",
    message: "",
  });
  var err = true;

  var validationErr = [];

  const validation = (err, element, value) => {
    if (err) {


      document.getElementById(element).classList.add("border-danger");
      validationErr[element] = value;
      setErrmsg(validationErr);
    }
    else {
      document.getElementById(element).classList.remove("border-danger");
    }

  }

  const setdata = (e) => {
    const { name, value } = e.target;
    validation(false, e.target.id, "");
    setINP((preval) => {
      return {
        ...preval,
        [name]: value,
      };
    });
  };

  const [featuredfile, setFile] = useState({});
  const uploadFile = (e) => {
    setFile({
      /* contains the preview, if you want to show the picture to the user
           you can access it with this.state.currentPicture
       */
      filePreview: URL.createObjectURL(e.target.files[0]),
      /* this contains the file we want to send */
      fileAsFile: e.target.files[0],
    });
  };


  const setCareer = async (event) => {
    event.preventDefault();
    if (!inpval.name) {
      let element = "name";
      validation(true, element, "Enter name");
    }
    if (!inpval.email) {
      let element = "email";
      validation(true, element, "Enter email");
    }
    if (!inpval.mobileno) {
      let element = "mobileno";
      validation(true, element, "Enter Mobile Number");
    }
    if (!inpval.message) {
      let element = "message";
      validation(true, element, "Enter message");
    }
    if (!inpval.name || !inpval.email || !inpval.mobileno || !inpval.message) {
      err = true;
    }
    else {
      err = false;
    }

    const formData = new FormData();
    formData.append("name", inpval.name);
    formData.append("email", inpval.email);
    formData.append("mobileno", inpval.mobileno);
    formData.append("message", inpval.message);
    formData.append("file", featuredfile?.fileAsFile);
    formData.append("others", "");

    if(!err){
      axios
      .post(`https://mail.intoggle.com/api/email`, formData, {

        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        swal("Sent", "Your Query is Submitted", "success");
        setINP({
          name: "",
          email: "",
          mobileno: "",
          message: "",
        });
        setFile({});
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };

  return (
    <div>
      <Navbar />
      {/*====== Start Page-banner section ======*/}
      <section className="page-banner bg_cover position-relative z-1">
        <div className="shape shape-one scene">
          <span data-depth={1}>
            <img src="assets/images/shape/shape-1.png" alt />
          </span>
        </div>
        {/* <div className="shape shape-two scene">
          <span data-depth={2}>
            <img src="assets/images/shape/shape-2.png" alt />
          </span>
        </div>
        <div className="shape shape-three scene">
          <span data-depth={3}>
            <img src="assets/images/shape/shape-3.png" alt />
          </span>
        </div>
        <div className="shape shape-four scene">
          <span data-depth={4}>
            <img src="assets/images/shape/shape-2.png" alt />
          </span>
        </div> */}
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="page-title text-center">
                <h1>Contact Us</h1>
                <ul className="breadcrumbs-link">
                  <li>
                    <Link href="/">Home</Link>
                  </li>
                  <li className="active">Contact Us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

      </section>
      {/*====== End Page-banner section ======*/}
      <section className="contact-info-v1 pt-130">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="section-title mb-60">
                <span className="sub-title st-one">Get In Touch</span>
                <h2>Have Any Project on Mind? Work Together</h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div
                    className="information-item info-item-one mb-30 wow fadeInUp "
                    data-wow-delay=".15s"
                  >
                    <div className="icon">
                      <i className="far fa-map-marker-alt" />
                    </div>
                    <div className="info">
                      <h4>Location</h4><br />
                      <p>
                        Plot No 18 A, Block G&amp;JU, Pitam Pura, Delhi, India 110088, IN<br /><br />

                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div
                    className="information-item info-item-one mb-30 wow fadeInUp "
                    data-wow-delay=".15s"
                  >
                    <div className="icon">
                      <i className="far fa-map-marker-alt" />
                    </div>
                    <div className="info">
                      <h4>Location</h4><br />
                      <p>

                        Plot No 23/1, Sector 9, Near Zonal Hospital, Solan, Himachal Pradesh 173212, IN<br />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div
                    className="information-item info-item-one mb-30 wow fadeInUp "
                    data-wow-delay=".15s"
                  >
                    <div className="icon">
                      <i className="far fa-map-marker-alt" />
                    </div>
                    <div className="info">
                      <h4>Location</h4><br />
                      <p>
                        Kinniburgh Cres, Chestermere, Albreta T1X0M1, CA<br /><br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-4 col-12">
                  <div className="">
                    <div
                      className="information-item info-item-two mb-30 wow fadeInUp pb-2 pt-3"
                      data-wow-delay=".20s"
                    >
                      <div className="icon">
                        <i className="far fa-envelope-open-text" />
                      </div>
                      <div className="info">
                        <h4>Email Address</h4>
                        <p>
                          <Link href="mailto:hr@infinitycareeredge.com">
                            hr@infinitycareeredge.com
                          </Link>
                        </p>
                        <br></br>
                        {/* <p>
                        <Link href="mailto;hotlinein@gmail.com">www.info.net</Link>
                      </p> */}
                      </div>
                    </div>
                  </div>

                </div>
                <div className="col-lg-4 col-md-4 col-12">
                  <div className="">
                    <div
                      className="information-item info-item-three wow fadeInUp pb-4 pt-3"
                      data-wow-delay=".25s"
                    >
                      <div className="icon">
                        <i className="far fa-phone" />
                      </div>
                      <div className="info">
                        <h4>Phone Number</h4>
                        <p>
                          <Link href="tel:+917018241327">+91- 701-824-1327</Link>
                        </p>
                        {/* <p>
                        <Link href="tel:+012(345)67899">+0123456</Link>
                      </p> */}
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>

          </div>
        </div>
      </section >
      {/*====== Start Contact section ======*/}
      < section className="contact-area contact-area-v3 pt-130 pb-130" >
        <div className="container-1350">
          <div className="contact-wrapper light-gray-bg pt-80 pb-80 wow fadeInUp">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-7">
                  <div className="section-title text-center mb-55">
                    <span className="sub-title st-one">Message Us</span>
                    <h2>Get Any Consultations ? Contact With Us</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="contact-form">
                    <form>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="form_group">
                            <input
                              type="text"
                              className="form_control"
                              placeholder="Full Name"
                              name="name"
                              id="name"
                              value={inpval.name}
                              onChange={setdata}
                              required
                            />

                          </div>

                        </div>
                        <div className="col-lg-4">
                          <div className="form_group">
                            <input
                              type="email"
                              className="form_control"
                              placeholder="Email Address "
                              name="email"
                              id="email"
                              required
                              value={inpval.email}
                              onChange={setdata}
                            />
                                                           

                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form_group">
                            <input
                              type="number"
                              className="form_control"
                              placeholder="Phone Number"
                              name="mobileno"
                              id="mobileno"
                              required
                              value={inpval.mobileno}
                              onChange={setdata}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form_group">
                            <textarea
                              className="form_control"
                              placeholder="Write Message"
                              name="message"
                              id="message"
                              defaultValue={""}
                              value={inpval.message}
                              onChange={setdata}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form_group text-center">
                            <button className="main-btn" onClick={setCareer}>
                              Send Us Message
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
      {/*====== End Contact section ======*/}
      < Footer />
    </div >
  );
};

export default Contact;
