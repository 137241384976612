import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import servicesdetails from "./Services/Servicecontent";

const Navbar = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [showdrop, setdropdown] = useState(false);

  const [active, setActive] = useState(false);

  const showMenu = () => {
    active === true ? setActive(false) : setActive(true);
  };

  const showdropdown = () => {
    active === true ? setdropdown(false) : setdropdown(true);
  };

  // const HideMenu = () => {

  // }

  const [subMenuActiveService, setSubMenuActiveService] = useState(false);

  const showSubMenuService = () => {
    console.log("for service-");
    console.log(subMenuActiveService);
    subMenuActiveService === true
      ? setSubMenuActiveService(false)
      : setSubMenuActiveService(true);
  };

  const [subMenuActiveBlog, setSubMenuActiveBlog] = useState(false);

  const showSubMenuBlog = () => {
    console.log("for blog-");
    console.log(subMenuActiveBlog);
    subMenuActiveBlog === true
      ? setSubMenuActiveBlog(false)
      : setSubMenuActiveBlog(true);
  };

  return (
    <div>
      {/*====== offcanvas-panel ======*/}
      <div className="offcanvas-panel">
        <div className="panel-overlay" />
        <div className="offcanvas-panel-inner">
          <div className="panel-logo">
            <Link to="/">
              <img src="/assets/images/logo/logo-1.png" alt="Qempo" />
            </Link>
          </div>
          <div className="about-us">
            <h5 className="panel-widget-title">About Us</h5>
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempore
              ipsam alias quae cupiditate quas, neque eum magni impedit
              asperiores.
            </p>
          </div>
          <div className="contact-us">
            <h5 className="panel-widget-title">Contact Us</h5>
            <ul>
              <li>
                <i className="fal fa-map-marker-alt" />
                121 King St, Melbourne VIC 3000, Australia.
              </li>
              <li>
                <i className="fal fa-envelope-open" />
                <Link to="mailto:hello@barky.com"> hello@barky.com</Link>
                <Link to="mailto:info@barky.com">info@barky.com</Link>
              </li>
              <li>
                <i className="fal fa-phone" />
                <Link to="tel:(312)-895-9800">(312) 895-9800</Link>
                <Link to="tel:(312)-895-9888">(312) 895-9888</Link>
              </li>
            </ul>
          </div>
          <Link to="#" className="panel-close">
            <i className="fal fa-times" />
          </Link>
        </div>
      </div>
      {/*====== offcanvas-panel ======*/}
      <div className="topnav d-lg-block d-none">
        <div className="container px-0">
          <div className="row align-items-center">
            <div className="col-lg-4">
              <a href="https://twitter.com/MukhiPrinkal" className="text-white mr-3" target="_blank">
                <i className="fab fa-twitter" />
              </a>
              <a href="https://www.linkedin.com/company/infinity-career-edge/" className="text-white mx-3" target="_blank">
                <i className="fab fa-linkedin" />
              </a>
              <a href="https://instagram.com/infinitycareeredge?igshid=ZDdkNTZiNTM=" className="text-white mx-3" target="_blank">
                <i className="fab fa-instagram" />
              </a>
              <a href="https://www.facebook.com/profile.php?id=100090830962033&mibextid=ZbWKwL" className="text-white mx-3" target="_blank">
                <i className="fab fa-facebook-f" />
              </a>
            </div>
            <div className="col-lg-8">
              <div className="row align-items-center py-2">
              <div className="text-white col-md-8 text-right">
                  <i class="fa fa-map-marker"> &nbsp;</i><span>Kinniburgh Cres, Chestermere,  T1X0M1, Canada</span>
                </div>
                <div className="text-white col-md-4 text-right">
                  <i class="fa fa-envelope"> &nbsp;</i><span>hr@infinitycareeredge.com</span>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*====== Start Header Section ======*/}
      <header className="theme-header transparent-header">
        {/* header Navigation */}
        <div
          className={`header-navigation navigation-style-v1 ${window.matchMedia("(max-width: 1199px)").matches
            ? "breakpoint-on"
            : ""
            }`}
        >
          {/* <div className="header-navigation navigation-style-v1"> */}
          <div className="nav-overlay" />
          <div className="container-fluid">
            <div className="primary-menu">
              <div className="site-branding">
                <Link to="/" className="brand-logo">
                  <img
                    src="/assets/images/logo/logo1.png"
                    height="70px"
                    alt="Site Logo"
                  />
                </Link>
              </div>
              <div className={`nav-menu col-md-9 ${active === true ? "menu-on" : ""}`}>
                {/* Navbar Close */}
                <div className="navbar-close">
                  <i className="far fa-times" />
                </div>
                {/* Nav Search */}
                {/* <div className="nav-search">
                  <ul className="m-auto">
                    <li className="bar-item">
                      <Link to="tel: 7018241327" className="callnowbtn px-3">
                        Call Now &nbsp;
                      </Link>
                    </li>
                  </ul>
                </div> */}
                {/* Main Menu */}
                <nav className="main-menu">
                  <ul className="my-auto menu-ul mr-0 ml-auto">
                    <li className="menu-item  pr-4 ">
                      <Link to="/" className="nav-link">
                        Home
                      </Link>
                    </li>
                    <li
                      className={`menu-item  pr-2 menu-item-has-children ${subMenuActiveBlog === true ? "active" : ""
                        }`}
                    >
                      <Link to="#" className="nav-link">
                        About us
                      </Link>
                      <ul
                        className="sub-menu"
                        style={{
                          display: `${window.matchMedia("(max-width: 1199px)").matches
                            ? (subMenuActiveBlog === true ? "block" : "none") : ""
                            }`,
                        }}
                      >
                        <li>
                          <Link to="/whoweare">Who we are</Link>
                        </li>
                        <li>
                          <Link to="/vissionandmission">
                            Vision and Mission
                          </Link>
                        </li>
                        <li>
                          <Link to="/whyus">Why us</Link>
                        </li>
                        <li>
                          <Link to="/ourcore">Our Core Values</Link>
                        </li>
                      </ul>
                      <span
                        class="dd-trigger2"
                        onClick={() => showSubMenuBlog()}
                      >
                        <i class="fas fa-angle-down"></i>
                      </span>
                    </li>
                    <li
                      className={`menu-item  pr-2 pl-md-4 menu-item-has-children ${subMenuActiveService === true ? "active" : ""
                        }`}
                    >
                      <Link to="#" className="nav-link">
                        Services
                      </Link>
                      <ul
                        className="sub-menu"
                        style={{
                          display: `${window.matchMedia("(max-width: 1199px)").matches
                            ? (subMenuActiveService === true ? "block" : "none") : ""
                            }`,
                        }}
                      >
                        {servicesdetails.map((service) => (
                          <li>
                            <Link
                              key={service._id}
                              to={`/service/${service.URL}`}
                              onClick={() => showMenu()}
                            >
                              {/* {service._id}. */}
                              {service.serviceName}
                            </Link>
                          </li>
                        ))}
                      </ul>
                      <span
                        class="dd-trigger2"
                        onClick={() => showSubMenuService()}
                      >
                        <i class="fas fa-angle-down"></i>
                      </span>
                    </li>
                    <li className="menu-item  pr-4 pl-md-4">
                      <Link to="/ourclients" className="nav-link">
                        Our Clients
                      </Link>
                    </li>
                    <li className="menu-item  pr-4 ">
                      <Link to="/careers" className="nav-link">
                        Careers
                      </Link>
                    </li>
                    <li className="menu-item  pr-0">
                      <Link to="/contact" className="nav-link">
                        Contact us
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="header-right-nav d-md-none">
                <ul className="m-auto">
                  {/* <li className="bar-item">
                    <Link to="tel: 7018241327" className="btn px-3">
                      Call Now &nbsp;
                    </Link>
                  </li> */}
                  <li className="navbar-toggle-btn">
                    <div
                      className={`navbar-toggler ${active === true ? "active" : ""
                        }`}
                      onClick={() => showMenu()}
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </li>
                  {/* <li className="navbar-toggle-btn">
                    <div className="navbar-toggler">
                      <span />
                      <span />
                      <span />
                    </div>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Navbar;
