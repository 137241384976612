import React, { useState } from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";
import axios from "axios";
import { Link } from "react-router-dom";
import swal from "sweetalert";

const Careers = () => {
  const [errMsg, setErrmsg] = useState([]);

  const [inpval, setINP] = useState({
    name: "",
    email: "",
    mobileno: "",
    message: "",
    qualification: "",
    address: "",
    test: "",
  });
  var err = true;

  var validationErr = [];

  const validation = (err, element, value) => {
    if (err) {
      document.getElementById(element).classList.add("border-danger");
      validationErr[element] = value;
      setErrmsg(validationErr);
    } else {
      document.getElementById(element).classList.remove("border-danger");
    }
  };

  const setdata = (e) => {
    const { name, value } = e.target;
    validation(false, e.target.id, "");

    setINP((preval) => {
      return {
        ...preval,
        [name]: value,
      };
    });
  };

  const [featuredfile, setFile] = useState({});
  const uploadFile = (e) => {
    setFile({
      /* contains the preview, if you want to show the picture to the user
           you can access it with this.state.currentPicture
       */
      filePreview: URL.createObjectURL(e.target.files[0]),
      /* this contains the file we want to send */
      fileAsFile: e.target.files[0],
    });
  };

  const others = `Qualification = ${inpval.qualification} <br/>`;

  const setCareer = async (event) => {
    event.preventDefault();
    if (!inpval.name) {
      let element = "name";
      validation(true, element, "Enter name");
    }
    if (!inpval.email) {
      let element = "email";
      validation(true, element, "Enter email");
    }
    if (!inpval.mobileno) {
      let element = "mobileno";
      validation(true, element, "Enter Mobile Number");
    }
    if (!inpval.qualification) {
      let element = "qualification";
      validation(true, element, "Enter qualification");
    }
    if (!inpval.message) {
      let element = "message";
      validation(true, element, "Enter message");
    }
    if (
      !inpval.name ||
      !inpval.email ||
      !inpval.mobileno ||
      !inpval.message ||
      !inpval.qualification
    ) {
      err = true;
    } else {
      err = false;
    }

    const formData = new FormData();
    formData.append("name", inpval.name);
    formData.append("email", inpval.email);
    formData.append("mobileno", inpval.mobileno);
    formData.append("message", inpval.message);
    formData.append("file", featuredfile?.fileAsFile);
    // formData.append("qualification", inpval.qualification);
    // formData.append("address", inpval.address);
    formData.append("others", others);

    if (!err) {
      axios
        .post(`https://mail.intoggle.com/api/email`, formData, {
          // .post(`http://localhost:8000/api/email`, formData, {

          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          swal("Sent", "Your Application is Submitted", "success");
          setINP({
            name: "",
            email: "",
            mobileno: "",
            message: "",
            qualification: "",
            address: "",
            test: "",
          });
          setFile({});
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div>
      <Navbar />
      {/*====== Start Page-banner section ======*/}
      <section className="page-banner bg_cover position-relative z-1">
        <div className="shape shape-one scene">
          <span data-depth={1}>
            <img src="assets/images/shape/shape-1.png" alt />
          </span>
        </div>
        {/* <div className="shape shape-two scene">
          <span data-depth={2}>
            <img src="assets/images/shape/shape-2.png" alt />
          </span>
        </div>
        <div className="shape shape-three scene">
          <span data-depth={3}>
            <img src="assets/images/shape/shape-3.png" alt />
          </span>
        </div>
        <div className="shape shape-four scene">
          <span data-depth={4}>
            <img src="assets/images/shape/shape-2.png" alt />
          </span>
        </div> */}
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="page-title text-center">
                <h1>Careers</h1>
                <ul className="breadcrumbs-link">
                  <li>
                    <Link href="/">Home</Link>
                  </li>
                  <li className="active">Careers</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*====== End Page-banner section ======*/}
      {/*====== Start Career section ======*/}
      <section className="portfolio-details-section pt-100 pb-130">
        <div className="container">
          <div className="portfolio-details-wrapper wow fadeInUp">
            <div className="portfolio-item">
              <div className="text">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="content">
                      <h3 className="title">Careers at Infinity Career Edge</h3>
                      <p>
                        Our mission is to be recognized as one of the most
                        sought-after and respected Talent Acquisition firms that
                        provides seamless, organized and customized services to
                        the clients while maintaining high values and decorum.
                        We aim to be the go-to organization for quick,
                        high-quality and professional HR solutions that help
                        clients find talent that will thrive in their
                        organizations. Creating value for the society by various
                        ways remains our priority.
                      </p>
                    </div>
                    <div className="content">
                      <h3 className="title">Join the Team</h3>
                      <p>
                        Infinity Career Edge have redefined the process of
                        hiring. The ethos of our organization is based on
                        delivering quality through a result-oriented approach.
                        We are driven by creativity and innovation, having
                        placed thousands of professionals in various endeavours
                        of their choice. We are not only dedicated, but fully
                        equipped with new-age tools and technologies that give
                        us a competitive edge.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="portfolio-info mb-45">
                      <ul>
                        <li>
                          <h4>Recruitment So Far</h4>
                          <p>2500+</p>
                        </li>
                        <li>
                          <h4>Happy Clients</h4>
                          <p>30+</p>
                        </li>
                        <li>
                          <h4>Years of Experience</h4>
                          <p>5+</p>
                        </li>
                        {/* <li>
                          <h4>Location</h4>
                          <p>55 Main Street, New York</p>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="content">
                      <img src="assets/images/about/img-1.webp" alt />
                      <h3 className="title">The pursuit of better </h3>
                      <p>
                        if you are agile in your thinking, adapt quickly to the
                        changing world, excited about working smarter & harder,
                        we want to hear from you. The constant quest for
                        improvement leads to personal development and expansion.
                        It entails creating plans, taking initiative, and
                        working steadily towards improving oneself.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ======start career form========== */}
      <section className="contact-area contact-area-v1 pt-70 pb-80 light-gray-bg">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8">
              <div className="text-wrapper mb-50 wow fade-left">
                <h2>
                  Reach <span className="fill-text">Us</span>
                </h2>
                <h4 className="blue-dark pb-2">Join the Team</h4>
                <div className="contact-form">
                  <form>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form_group">
                          <label>
                            <b>Full Name</b>
                          </label>
                          <input
                            type="text"
                            className="form_control"
                            placeholder="Full Name"
                            name="name"
                            id="name"
                            required
                            value={inpval.name}
                            onChange={setdata}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form_group">
                          <label>
                            <b>Mobile No.</b>
                          </label>
                          <input
                            type="number"
                            className="form_control"
                            placeholder="Mobile no"
                            name="mobileno"
                            id="mobileno"
                            required
                            value={inpval.mobileno}
                            onChange={setdata}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form_group">
                          <label>
                            <b>Email Address</b>
                          </label>
                          <input
                            type="email"
                            className="form_control"
                            placeholder="Email Address"
                            name="email"
                            id="email"
                            required
                            value={inpval.email}
                            onChange={setdata}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form_group">
                          <label>
                            <b>Qualification</b>
                          </label>
                          <input
                            type="text"
                            className="form_control"
                            placeholder="Qualification"
                            name="qualification"
                            id="qualification"
                            required
                            value={inpval.qualification}
                            onChange={setdata}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form_group">
                          <label>
                            <b>Resume File</b>
                          </label>
                          <input
                            type="file"
                            id="file"
                            name="featuredimage"
                            className="filebtn"
                            onChange={uploadFile}
                          />
                        </div>
                      </div>

                      {/* <div className="col-lg-6">
                        <div className="form_group">
                          <label>
                            <b>address</b>
                          </label>
                          <input
                            type="text"
                            className="form_control"
                            placeholder="address"
                            name="address"
                            required
                            value={inpval.address}
                            onChange={setdata}
                          />
                        </div>
                      </div> */}
                      {/* <div className="col-lg-6">
                        <div className="form_group">
                          <label>
                            <b>test field</b>
                          </label>
                          <input
                            type="text"
                            className="form_control"
                            placeholder="test"
                            name="test"
                            required
                            value={inpval.test}
                            onChange={setdata}
                          />
                        </div>
                      </div> */}
                      {/* <div className="col-lg-6">
                        <div className="form_group" key={index}>
                          <label>
                            <b>Field name</b>
                          </label>
                          <input
                            name="name"
                            className="form_control"
                            placeholder="Field name"
                            value={input.name}
                            onChange={(event) => handleFormChange(index, event)}
                          />
                        </div>
                      </div> */}
                      {/* {inputFields.map((input, index) => {
                        return (
                          <>
                            <div className="col-lg-6">
                              <div className="form_group" key={index}>
                                <label>
                                  <b>Field value</b>
                                </label>
                                <input
                                  name="age"
                                  className="form_control"
                                  placeholder="Field value"
                                  value={input.age}
                                  onChange={(event) =>
                                    handleFormChange(index, event)
                                  }
                                />
                              </div>
                            </div>
                            <button
                              className="main-btn btn-danger "
                              onClick={() => removeFields(index)}
                            >
                              {" "}
                              Remove
                            </button>
                          </>
                        );
                      })} */}
                      {/* <div className="col-lg-12 pt-3 pb-2">
                        <div className="form_group">
                          <button
                            className="main-btn btn-primary arrow-btn"
                            onClick={addFields}
                          >
                            Add More Fields
                          </button>
                        </div>
                      </div> */}
                      <div className="col-lg-12 pt-40">
                        <div className="form_group">
                          <label>
                            <b>Message</b>
                          </label>
                          <textarea
                            className="form_control"
                            placeholder="Write Message"
                            name="message"
                            id="message"
                            // defaultValue={""}
                            value={inpval.message}
                            onChange={setdata}
                          />
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form_group">
                          <button
                            className="main-btn arrow-btn"
                            onClick={setCareer}
                          >
                            Send Us Message
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="img-holder mb-50 wow fade-right">
                <div className="shape shape-icon-one scene">
                  <span data-depth={1}>
                    <img src="assets/images/shape/shape-4.png" alt />
                  </span>
                </div>
                <div className="shape shape-icon-two">
                  <span />
                </div>
                <img
                  src="assets/images/about/hero-three-img-1-1.png"
                  alt="Contact Image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Careers;

{
  /* <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-12">
                    <div className="text-wrapper mb-50 wow fadeInLeft">
                      <h2 className="blue-dark">Reach Us</h2>
                      <h4>Send us your Details</h4>
                      <div className="contact-form">
                        <form>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form_group">
                                <input
                                  type="text"
                                  className="form_control"
                                  placeholder="Full Name"
                                  name="name"
                                  required
                                  value={inpval.name}
                                  onChange={setdata}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form_group">
                                <input
                                  type="email"
                                  className="form_control"
                                  placeholder="Email Address"
                                  name="email"
                                  required
                                  value={inpval.email}
                                  onChange={setdata}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form_group">
                                <input
                                  type="number"
                                  className="form_control"
                                  placeholder="Mobile no"
                                  name="mobileno"
                                  required
                                  value={inpval.mobileno}
                                  onChange={setdata}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form_group">
                                <input
                                  type="file"
                                  name="featuredimage"
                                  className="filebtn pt-15 pb-15 "
                                  onChange={uploadFile}
                                />
                              </div>
                            </div>
                            {inputFields.map((input, index) => {
                              return (
                                <div className="col-lg-6 pb-2">
                                  <div className="form_group " key={index}>
                                    <input
                                      name="name"
                                      className="form_control"
                                      placeholder="Fieldname"
                                      value={input.name}
                                      onChange={(event) =>
                                        handleFormChange(index, event)
                                      }
                                    />
                                    <input
                                      name="age"
                                      className="form_control"
                                      placeholder="Field values"
                                      value={input.age}
                                      onChange={(event) =>
                                        handleFormChange(index, event)
                                      }
                                    />
                                  </div>
                                  <button
                                    className="main-btn btn-danger "
                                    onClick={() => removeFields(index)}
                                  >
                                    {" "}
                                    Remove
                                  </button>
                                </div>
                              );
                            })}
                            <div className="col-lg-12 pt-3 pb-2">
                              <div className="form_group">
                                <button
                                  className="main-btn btn-primary arrow-btn"
                                  onClick={addFields}
                                >
                                  Add More Fields
                                </button>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="form_group">
                                <textarea
                                  className="form_control"
                                  placeholder="Write Message"
                                  name="message"
                                  // defaultValue={""}
                                  value={inpval.message}
                                  onChange={setdata}
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="form_group">
                                <button
                                  className="main-btn arrow-btn"
                                  onClick={setCareer}
                                >
                                  Send Us Message
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */
}
