import React from "react";
import servicesdetails from "./Services/Servicecontent";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      {/*====== Start Footer ======*/}
      <footer className="footer-area">
        <div className="container">
          <div className="footer-top ">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="footer-logo mb-40 wow fadeInLeft text-center">
                  <img
                    src="/assets/images/logo/logo2.jpeg"
                    width="90%"
                    alt="Brand Logo"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-wrapper mb-40 wow fadeInRight text-center">
                  <h3>
                    Modern Solutions For &nbsp;
                    <span className="blue-dark"> Creative Agency</span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-widget pt-70 pb-40">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div
                  className="widget footer-nav-widget mb-40 wow fadeInUp"
                  data-wow-delay=".15s"
                >
                  <h4 className="widget-title">Services</h4>
                  <ul className="widget-nav">
                    {servicesdetails.map((service) => (
                      <li>
                        <Link key={service._id} to={`/service/${service.URL}`}>
                          {service.serviceName}
                        </Link>
                      </li>
                    ))}
                    {/* <li>
                      <Link to="/services">Permanent Staffing </Link>
                    </li>
                    <li>
                      <Link to="/services">Contract staffing </Link>
                    </li>
                    <li>
                      <Link to="/services">Executive search</Link>
                    </li>
                    <li>
                      <Link to="/services">Campus Recruitment</Link>
                    </li>
                    <li>
                      <Link to="/services">End to End HR Solutions</Link>
                    </li> */}
                    {/* <li>
                      <Link to="services.html">Brand Design</Link>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div
                  className="widget footer-nav-widget mb-40 wow fadeInUp"
                  data-wow-delay=".20s"
                >
                  <h4 className="widget-title">Links</h4>
                  <ul className="widget-nav">
                    <li>
                      <Link to="/whoweare">About Us</Link>
                    </li>
                    <li>
                      <Link to="/careers">Careers</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/ourclients">Our Clients</Link>
                    </li>
                    {/* <li>
                      <Link to="/"></Link>
                    </li>
                    <li>
                      <Link to="/"></Link>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12">
                <div
                  className="widget social-widget mb-40 wow fadeInUp"
                  data-wow-delay=".25s"
                >
                  <h4 className="widget-title">Follow</h4>
                  <ul className="social-nav">
                    {/* <li>
                      <Link to="#">
                        <i className="fab fa-dribbble" />
                        Dribbble
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link to="#">
                        <i className="fab fa-instagram" />
                        Instagram
                      </Link>
                    </li> */}
                    <li>
                      <a href="https://twitter.com/MukhiPrinkal" target="_blank">
                        <i className="fab fa-twitter" />
                        Twitter
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/infinity-career-edge/" target="_blank">
                        <i className="fab fa-linkedin" />
                        LinkedIn
                      </a>
                    </li>
                    <li>
                      <a href="https://instagram.com/infinitycareeredge?igshid=ZDdkNTZiNTM=" target="_blank">
                      <i class="fab fa-instagram"></i>
                        Instagram
                      </a>
                    </li>
                    <li>
                      <a href="https://www.facebook.com/profile.php?id=100090830962033&mibextid=ZbWKwL" target="_blank">
                        <i className="fab fa-facebook-f" />
                        Facebook
                      </a>
                    </li>
                    {/* <li>
                      <Link to="#">
                        <i className="fab fa-medium-m" />
                        Medium
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div
                  className="widget newsletter-widget mb-40 wow fadeInUp"
                  data-wow-delay=".30s"
                >
                  <div className="newsletter-content">
                    <h3>About Us</h3>
                    <p>
                      Infinity Career Edge provide some of the most extensive
                      services to help carry out your HR-related functions in a
                      structured manner.
                    </p>
                    {/* <form>
                      <div className="form_group">
                        <input
                          type="email"
                          className="form_control"
                          placeholder="Email Address"
                          name="email"
                          required
                        />
                        <button className="main-btn">Sign Up</button>
                      </div>
                    </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-copyright">
            <div className="row">
              <div className="col-lg-6 ">
                <div className="copyright-text ">
                  <p>
                    © 2024. All rights reserved | Powered by{" "}
                    <a href="https://www.intoggle.com/" target="_blank">
                      <b>Intoggle</b>
                    </a>
                  </p>
                </div>
              </div>
              {/* <div className="col-lg-6">
                <div className="footer-nav float-lg-right">
                  <ul>
                    <li>
                      <Link to="service-details.html">Contact </Link>
                    </li>
                    <li>
                      <Link to="service-details.html">Faqs</Link>
                    </li>
                    <li>
                      <Link to="service-details.html">Services</Link>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </footer>
      {/*====== End Footer ======*/}
      {/*====== back-to-top ======*/}
      <Link to="#" onClick={() => window.scrollTo(0,0)} className="back-to-top">
        <i className="far fa-angle-up" />
      </Link>
    </div>
  );
};

export default Footer;
