import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./components/Home";
// import Services from "./components/Services/Services";
// import Aboutus from "./components/Aboutus";
// import Servicesdetails from "./components/Services/Servicesdetails";
// import Blogs from "./components/Blogs";
// import Blogdetails from "./components/Blogdetails";
import Contact from "./components/Contact";
// import Teamdetails from "./components/Teamdetails";
// import Portfolio from "./components/Portfolio";
import Vission from "./components/Aboutus/Vission";
import Whoarewe from "./components/Aboutus/Whoarewe";
import Whyus from "./components/Aboutus/Whyus";
import Corevalues from "./components/Aboutus/Corevalues";
import Servicedetail from "./components/Services/Servicedetail";
import Careers from "./components/Careers/Careers";
import Ourclients from "./components/ourclients/Ourclients";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* home */}
        <Route path="/" element={<Home />} />
        {/* about us */}
        <Route path="/whoweare" element={<Whoarewe />} />
        <Route path="/vissionandmission" element={<Vission />} />
        <Route path="/whyus" element={<Whyus />} />
        <Route path="/ourcore" element={<Corevalues />} />
        {/* services */}
        <Route path="/service/:URL" element={<Servicedetail />} />
        {/* careers */}
        <Route path="/careers" element={<Careers />} />
        {/* others */}
        <Route path="/contact" element={<Contact />} />
        <Route path="/ourclients" element={<Ourclients />} />


        {/* <Route path="/aboutus" element={<Aboutus />} /> */}
        {/* <Route path="/servicesdetails" element={<Servicesdetails />} /> */}
        {/* <Route path="/blogs" element={<Blogs />} /> */}
        {/* <Route path="/blogdetails" element={<Blogdetails />} /> */}
        {/* <Route path="/teamdetails" element={<Teamdetails />} /> */}
        {/* <Route path="/ourportfolio" element={<Portfolio />} /> */}
        {/* <Route path="/portfoliodetails" element={<Career />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
