import React from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";
import { Link } from "react-router-dom";


const Whoarewe = () => {
  
  return (
    <div>
      <Navbar />
      {/*====== Start Page-banner section ======*/}
      <section className="page-banner bg_cover position-relative z-1">
        <div className="shape shape-one scene">
          <span data-depth={1}>
            <img src="assets/images/shape/shape-1.png" alt />
          </span>
        </div>
        {/* <div className="shape shape-two scene">
          <span data-depth={2}>
            <img src="assets/images/shape/shape-2.png" alt />
          </span>
        </div>
        <div className="shape shape-three scene">
          <span data-depth={3}>
            <img src="assets/images/shape/shape-3.png" alt />
          </span>
        </div>
        <div className="shape shape-four scene">
          <span data-depth={4}>
            <img src="assets/images/shape/shape-2.png" alt />
          </span>
        </div> */}
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="page-title">
                <h1>Who We Are</h1>
                <ul className="breadcrumbs-link">
                  <li>
                    <Link href="/">Home</Link>
                  </li>
                  <li className="active">Who We Are</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="page-banner-img">
                <img src="assets/images/about/img-5.webp" alt />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*====== End Page-banner section ======*/}
      {/*====== Start About section ======*/}
      <section className="about-area light-gray-bg about-area-v3 pt-130 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-wrapper mb-50 wow fadeInRight">
                <div className="section-title mb-25">
                  <span className="sub-title st-one">About Us</span>
                  {/* <h2>Best Digital Solutiuon Provider Agency</h2> */}
                  <h2>Company Overview</h2>

                  <p className="blue-dark">
                    Professional Agency to provide Consulting solutions
                  </p>
                </div>
                <p>
                  Infinity Career Edge is a Human Resources Consulting company
                  has been immensely successful in creating a global network of
                  highly adept intelligent workforce that can help a company
                  achieve its mission-critical projects and goals. Keeping pace
                  with projects and being on the lookout for the extremely
                  talented individuals has become ever more challenging. Here is
                  where we comes into play to bring the best suited talent for
                  your company.
                </p>
                <p>
                  We are a global corporation offering staffing solutions,
                  Consulting and Outsourcing. Our company brings together
                  Learning and Development, Talent Management, and Performance
                  Management solutions to diverse clients. As a company, we
                  strive for exceptionally high standards in all our services,
                  creating tailored services that leave a lasting impression.
                </p>
                <p>
                  We understand that your organization is your dream and we are
                  here to share your toil and make it feasible for you to only
                  focus on your core venture while we take over your recruitment
                  process.
                </p>
                <p>
                  Infinity Career Edge provide some of the most extensive
                  services to help carry out your HR-related functions in a
                  structured manner.
                </p>
                {/* <ul className="list-style-one mb-35">
                  <li>Digital Creative Agency</li>
                  <li>Professional Problem Solutions</li>
                  <li>Web Design &amp; Development</li>
                </ul> */}
                {/* <Link
                  href="/aboutus"
                  className="main-btn bordered-btn btn-blue arrow-btn"
                >
                  More About Us
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Whoarewe;
