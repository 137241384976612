const servicesdetails = [
    {
        _id:"1",
        serviceName:"Permanent Staffing ",
        Discription:"Being one of the reputed staffing companies, Infinity Career Edge has been established with the sole objective to dedicate itself for quality service in Human Resource Sector. With the highly efficient staff, we ensure that the client organizations get an effective solution for their manpower need on a timely basis.Our core expertise is delivering quality permanent candidates to our clients through our fast, easy and friendly staffing solution services.",
        shortdesc:"Every organization continuously seeks quality and dedicated permanent staff that holds credible experience and promise of succeeding in the industry. Getting the right candidates at the right time is every HR department’s key operational challenge.",
        subheading:"Permanent Staffing ",
        tableheading1:"Selecting qualified applicants.",
        tableheading2:"Processes in the Permanent Staffing.",
        tableheading3:"Permanent employment.",
        tablecontent1:"The practice of selecting qualified applicants to fill permanent openings in a company.        ",
        tablecontent2:"Job posting, candidate screening, interviews, and selection are some of the processes in the permanent staffing process.",
        tablecontent3:"For enterprises that need long-term workers to maintain operations and accomplish commercial objectives, permanent employment is essential.",
        serviceimage:'/assets/images/breadcrumb/img-2.webp',
        URL:"permanent-staffing",
        icon: "flaticon-strategy",
        // link: "/services",
        features: ["Selecting qualified applicants. ", "Processes in the Permanent Staffing. ", "Permanent employment."]
    },
    
    {
        _id:"2",
        serviceName:"Contract Staffing  ",
        Discription:"Contract staffing is preferred for projects that have a shorter-term but immediate need for higly-skilled talent. Infinity Career Edge provides companies with contract staffing solutions by hiring professionals with demonstrable expertise in delivering on such projects.",
        shortdesc:"Short-term solutions for long-term goals. Variety of staffing solutions relied on by other organizations to meet their growing needs.",
        subheading:"Contract staffing",
        tableheading1:"Short-term solutions for your goals",
        tableheading2:"Variety of staffing solutions",
        // tableheading3:"",
        tablecontent1:"Contract staffing ensures that a business always has the necessary people on hand to handle consumer communication efficiently.",
        tablecontent2:"Companies can scale their customer support operations up or down as necessary to meet changing client demand thanks to contact staffing.",
        // tablecontent3:"",
        serviceimage:'/assets/images/breadcrumb/img-3.webp',
        URL:"contract-staffing",
        icon: "flaticon-design",
        // link: "/services",
        features: ["Short-term solutions for long-term goals", "Variety of staffing solutions"]
    }, {
        _id:"3",
        serviceName:"Executive Search ",
        Discription:"We recruit for executive level positions like CEOs, Managing directors, Vice presidents and more. Our head-hunters are professionally trained to ensure confidentiality. With proper channeling and network overseas, we promise to deliver the datelines set by our clients. We work closely with the Board of Directors, chairman, CHROs, CEOs, and other high-level decision-makers to understand their needs for the best leadership talent at the top management levels which will facilitate smooth execution of business strategies.",
        shortdesc:"Infinity Career Edge helps in recruiting individuals for the critical tasks of leading the company or setting up a particular business line or entering a new market. ",
        subheading:"Executive Search",
        tableheading1:"Hire top-performing executives",
        tableheading2:"Fruitful executive search.",
        // tableheading3:"",
        tablecontent1:"A specialised hiring procedure called executive search is used to locate and hire top-performing executives for senior roles within firms. Finding and hiring important executives who can promote growth, creativity, and productivity inside an organisation is an essential task. To effectively target top talent, executive search firms must have a thorough awareness of the client's culture, strategic goals, and requirements. A fruitful executive search guarantees that businesses have access to the most gifted, accomplished, and successful leaders in their professions, which can have a big impact on how successful the company Is.",
        // tablecontent2:"",
        // tablecontent3:"",
        serviceimage:'/assets/images/breadcrumb/img-6.webp',
        URL:"executive-search",
        icon: "fa fa-search",
        // link: "/services",
        features: ["Hire top-performing executives ","Fruitful executive search."]
    }, {
        _id:"4",
        serviceName:"Campus Recruitment ",
        Discription:" Infinity Career Edge can fulfill high volume campus recruitment needs to source talent that is aligned with our client’s needs. Our campus recruitment services are a one-stop solution for both our clients and colleges for running a smooth and efficient campus placement drive. On the other hand, such a pool of pinnacle talent also prefers us because of our collaborations with some of the topmost companies in the industry today. They are well aware of the abundant opportunities that can be unlocked at a single, exclusive gateway.",
        shortdesc:"The objective of campus recruitment initiative is to select and hire the talented and qualified candidates while they are still completing their education. The process allows the organizations to pick the required candidates as and when needed and also reduces time and to support the HR to take quick decision by predefined screening. ",
        subheading:"Campus Recruitment",
        tableheading1:"Hiring process.",
        tableheading2:"Chance to network.",
        // tableheading3:"",
        tablecontent1:"Campus recruitment is the procedure wherein businesses go to educational institutions like colleges and universities to find and hire talented individuals for career openings. For many businesses, campus recruitment is a crucial step in the hiring process since it enables them to meet with a lot of prospects quickly. Also, it gives students the chance to network with possible employers and find out about employment openings in their field of study.",
        tablecontent2:"Companies frequently hold pre-placement discussions during campus recruitment to describe their firm, the job vacancies open, and the qualifications they want in possible candidates. To determine whether a candidate is qualified for a position, businesses may also hold personal interviews, group talks, and aptitude testing.",
        // tablecontent3:"",
        serviceimage:'/assets/images/breadcrumb/img-4.webp',
        URL:"campus-recuitment",
        icon: "fa fa-graduation-cap",
        // link: "/services",
        features: ["Hiring process.", "Chance to network."]
    }, {
        _id:"5",
        serviceName:"End To End HR Solutions ",
        Discription:"Infinity Career Edge end-to-end HR solutions eliminate costs and gaps at intersection points of people and data, in various HR processes. This allows us to function with both, ownership and accountability towards the final metrics of evaluating the contribution of the HR function in an organization. Our management, human resource, and training services are designed to improve productivity,efficiency,communication and employee morale.",
        shortdesc:"Seamlessness and scalability of HR practices require special attention. Cost of operating HR functions in separate silos is also a concern in growing organizations.",
        subheading:"End To End HR Solutions ",
        tableheading1:"Recruitment and onboarding",
        tableheading2:"HRIS",
        // tableheading3:"",
        tablecontent1:"Recruitment and onboarding: These services assist businesses in finding, interviewing, and hiring the most qualified job applicants. They offer resources for publishing job postings, handling applicant tracking systems, holding interviews, and integrating new hires.",
        tablecontent2:"Human Resource Information System (HRIS): An HRIS is a software programme that aids businesses in managing employee data such as benefits, performance reviews, performance histories, and personal details. Additionally, this technology assists in automating HR operations including benefit administration, payroll processing, and time tracking.",
        tablecontent3:"In general, end-to-end HR solutions can assist businesses in better managing their staff, lowering administrative costs, and enhancing worker performance.",
        serviceimage:'/assets/images/breadcrumb/img-5.webp',
        URL:"end-to-end-hr-solutions",
        icon: "flaticon-source-code",
        // link: "/services",
        features: ["Recruitment and onboarding", "HRIS"]
    },
];
export default servicesdetails;