import React from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";
import { Link } from "react-router-dom";

const Corevalues = () => {
  return (
    <>
      <Navbar />
      {/*====== Start Page-banner section ======*/}
      <section className="page-banner bg_cover position-relative z-1">
        <div className="shape shape-one scene">
          <span data-depth={1}>
            <img src="assets/images/shape/shape-1.png" alt />
          </span>
        </div>
        {/* <div className="shape shape-two scene">
          <span data-depth={2}>
            <img src="assets/images/shape/shape-2.png" alt />
          </span>
        </div>
        <div className="shape shape-three scene">
          <span data-depth={3}>
            <img src="assets/images/shape/shape-3.png" alt />
          </span>
        </div>
        <div className="shape shape-four scene">
          <span data-depth={4}>
            <img src="assets/images/shape/shape-2.png" alt />
          </span>
        </div> */}
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="page-title">
                <h1>Our Core Values</h1>
                <ul className="breadcrumbs-link">
                  <li>
                    <Link href="/">Home</Link>
                  </li>
                  <li className="active">Our Core Values</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="page-banner-img">
                <img src="assets/images/about/about-3.webp" alt />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*====== End Page-banner section ======*/}
      {/* ========= Core values =========== */}
      <section className="features-area light-gray-bg pt-100">
        <div className="container">
          <div className="row">
          <div className="col-lg-12">
              <div className="text-wrapper mb-50 wow fadeInLeft">
                <div className="section-title mb-30">
                  {/* <span className="sub-title st-one">Features</span> */}
                  <h2>Our Core values</h2>
                  {/* <p className="blue-dark">
                    Professional Design Agency to provide solutions
                  </p> */}
                </div>
                {/* <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptate
                  accusantium doloremque laudantium totam
                </p> */}
                {/* <Link href="/aboutus" className="main-btn">
                  Discover More
                </Link> */}
              </div>
            </div>
            <div className="col-lg-12">
              <div className="features-list mb-50 wow fadeInRight">
                <div className="features-item mb-30">
                  <div className="text">
                    <h4>Purpose & Care </h4>
                    <p>
                    Business ethics are the foundation for our success. Caring for employees and clients, as well as the environment and the community we exist in, are paramount to how we conduct business.
                    </p>
                  </div>
                </div>
                <div className="features-item mb-30">
                  <div className="text">
                    <h4>Integrity</h4>
                    <p>
                    To have honesty and respect for all individuals.
                    </p>
                  </div>
                </div>
                <div className="features-item mb-30">
                  <div className="text">
                    <h4>Leadership</h4>
                    <p>
                    To empower and inspire our people.
                    </p>
                  </div>
                </div>
                <div className="features-item mb-30">
                  <div className="text">
                    <h4>Professionalism</h4>
                    <p>
                    To be professional in our actions to our clients, partners, and each other
                    </p>
                  </div>
                </div>
                <div className="features-item mb-30">
                  <div className="text">
                    <h4>Excellence</h4>
                    <p>
                    To continually pursue knowledge and learn. 
                    </p>
                  </div>
                </div>
                <div className="features-item mb-30">
                  <div className="text">
                    <h4>Fun</h4>
                    <p>
                    To have enjoyment and fulfillment in our work.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ================End================= */}
      <Footer />
    </>
  );
};

export default Corevalues;
