import React from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";
import { Link } from "react-router-dom";

const Ourclients = () => {
  return (
    <>
      <Navbar />
      {/*====== Start Page-banner section ======*/}
      <section className="page-banner bg_cover position-relative z-1">
        <div className="shape shape-one scene">
          <span data-depth={1}>
            <img src="assets/images/shape/shape-1.png" alt />
          </span>
        </div>
        {/* <div className="shape shape-two scene">
          <span data-depth={2}>
            <img src="assets/images/shape/shape-2.png" alt />
          </span>
        </div>
        <div className="shape shape-three scene">
          <span data-depth={3}>
            <img src="assets/images/shape/shape-3.png" alt />
          </span>
        </div>
        <div className="shape shape-four scene">
          <span data-depth={4}>
            <img src="assets/images/shape/shape-2.png" alt />
          </span>
        </div> */}
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="page-title text-center">
                <h1>Our Clients</h1>
                <ul className="breadcrumbs-link">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li className="active">Our Clients</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*====== End Page-banner section ======*/}

      {/*====== Start Partners Section ======*/}
      <section className="partners-area pt-125 light-gray-bg pb-130">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-title text-center mb-55 wow fadeInUp">
                <h2>We’ve 30+ Global Partners</h2>
                {/* <p></p> */}
              </div>
            </div>
          </div>
          <div className="row d-flex align-items-center  justify-content-center ">
            <div className="col-4 col-md-2 p-4  ">
              <div className="img-holder text-center">
                <img src="/assets/images/ourclients/1.webp" alt />
              </div>
            </div>
            <div className="col-4 col-md-2 p-4  ">
              <div className="img-holder text-center ">
                <img src="/assets/images/ourclients/2.webp" alt />
              </div>
            </div>{" "}
            <div className="col-4 col-md-2 p-4  ">
              <div className="img-holder text-center ">
                <img src="/assets/images/ourclients/3.webp" alt />
              </div>
            </div>{" "}
            <div className="col-4 col-md-2 p-4  ">
              <div className="img-holder text-center ">
                <img
                  className="client-image"
                  src="/assets/images/ourclients/4.webp"
                  alt
                />
              </div>
            </div>{" "}
            <div className="col-4 col-md-2 p-4  ">
              <div className="img-holder text-center ">
                <img src="/assets/images/ourclients/5.webp" alt />
              </div>
            </div>{" "}
            <div className="col-4 col-md-2 p-4  ">
              <div className="img-holder text-center ">
                <img src="/assets/images/ourclients/26.webp" alt />
              </div>
            </div>{" "}
          </div>
          <div className="row d-flex align-items-center justify-content-center ">
            <div className="col-4 col-md-2 p-4  ">
              <div className="img-holder text-center ">
                <img src="/assets/images/ourclients/6.webp" alt />
              </div>
            </div>{" "}
            <div className="col-4 col-md-2 p-4  ">
              <div className="img-holder text-center ">
                <img src="/assets/images/ourclients/7.webp" alt />
              </div>
            </div>{" "}
            <div className="col-4 col-md-2 p-4  ">
              <div className="img-holder text-center">
                <img src="/assets/images/ourclients/8.webp" alt />
              </div>
            </div>{" "}
            <div className="col-4 col-md-2 p-4  ">
              <div className="img-holder text-center ">
                <img src="/assets/images/ourclients/9.webp" alt />
              </div>
            </div>{" "}
            <div className="col-4 col-md-2 p-4  ">
              <div className="img-holder text-center ">
                <img src="/assets/images/ourclients/10.webp" alt />
              </div>
            </div>{" "}
            <div className="col-4 col-md-2 p-4  ">
              <div className="img-holder text-center ">
                <img src="/assets/images/ourclients/27.webp" alt />
              </div>
            </div>{" "}
          </div>
          <div className="row d-flex align-items-center justify-content-center">
            <div className="col-4 col-md-2 p-4  ">
              <div className="img-holder text-center">
                <img src="/assets/images/ourclients/11.webp" alt />
              </div>
            </div>{" "}
            <div className="col-4 col-md-2 p-4  ">
              <div className="img-holder text-center">
                <img src="/assets/images/ourclients/12.webp" alt />
              </div>
            </div>{" "}
            <div className="col-4 col-md-2 p-4  ">
              <div className="img-holder text-center">
                <img src="/assets/images/ourclients/13.webp" alt />
              </div>
            </div>{" "}
            <div className="col-4 col-md-2 p-4  ">
              <div className="img-holder text-center">
                <img src="/assets/images/ourclients/14.webp" alt />
              </div>
            </div>{" "}
            <div className="col-4 col-md-2 p-4  ">
              <div className="img-holder text-center">
                <img src="/assets/images/ourclients/15.webp" alt />
              </div>
            </div>{" "}
            <div className="col-4 col-md-2 p-4  ">
              <div className="img-holder text-center ">
                <img src="/assets/images/ourclients/28.webp" alt />
              </div>
            </div>{" "}
          </div>
          <div className="row d-flex align-items-center justify-content-center ">
            <div className="col-4 col-md-2 p-4  ">
              <div className="img-holder text-center">
                <img src="/assets/images/ourclients/16.webp" alt />
              </div>
            </div>{" "}
            <div className="col-4 col-md-2 p-4  ">
              <div className="img-holder text-center ">
                <img src="/assets/images/ourclients/17.webp" alt />
              </div>
            </div>{" "}
            <div className="col-4 col-md-2 p-4  ">
              <div className="img-holder text-center ">
                <img src="/assets/images/ourclients/18.webp" alt />
              </div>
            </div>{" "}
            <div className="col-4 col-md-2 p-4  ">
              <div className="img-holder text-center">
                <img src="/assets/images/ourclients/19.webp" alt />
              </div>
            </div>{" "}
            <div className="col-4 col-md-2 p-4  ">
              <div className="img-holder text-center ">
                <img src="/assets/images/ourclients/20.webp" alt />
              </div>
            </div>{" "}
            <div className="col-4 col-md-2 p-4  ">
              <div className="img-holder text-center ">
                <img src="/assets/images/ourclients/29.webp" alt />
              </div>
            </div>{" "}
          </div>
          <div className="row d-flex align-items-center justify-content-center ">
            <div className="col-4 col-md-2 p-4  ">
              <div className="img-holder text-center">
                <img src="/assets/images/ourclients/21.webp" alt />
              </div>
            </div>{" "}
            <div className="col-4 col-md-2 p-4  ">
              <div className="img-holder text-center">
                <img src="/assets/images/ourclients/22.webp" alt />
              </div>
            </div>{" "}
            <div className="col-4 col-md-2 p-4  ">
              <div className="img-holder text-center ">
                <img src="/assets/images/ourclients/23.webp" alt />
              </div>
            </div>{" "}
            <div className="col-4 col-md-2 p-4  ">
              <div className="img-holder text-center ">
                <img src="/assets/images/ourclients/24.webp" alt />
              </div>
            </div>{" "}
            <div className="col-4 col-md-2 p-4  ">
              <div className="img-holder text-center ">
                <img src="/assets/images/ourclients/25.webp" alt />
              </div>
            </div>{" "}
            <div className="col-4 col-md-2 p-4  ">
              <div className="img-holder text-center ">
                <img src="/assets/images/ourclients/30.webp" alt />
              </div>
            </div>
          </div>
          {/* <div className="row d-flex align-items-center justify-content-center "></div> */}
          {/* <div className="row d-flex align-items-center justify-content-center p-4 mb-4">
            <div className="col-2">
              <div className="img-holder text-center ">
                <img src="/assets/images/ourclients/31.jpg" alt />
              </div>
            </div>{" "}
          </div> */}
        </div>
      </section>
      {/*====== End Partners Section ======*/}

      <Footer />
    </>
  );
};

export default Ourclients;
