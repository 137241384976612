import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import servicesdetails from "./Services/Servicecontent";
import swal from "sweetalert";

const Home = () => {
  // nodemailer post req===================

  const [activeLoader, setActiveLoader] = useState({
    loading: true,
    error: null,
  });

  const [errMsg, setErrmsg] = useState([]);

  const [inpval, setINP] = useState({
    name: "",
    email: "",
    mobileno: "",
    message: "",
  });
  var err = true;

  var validationErr = [];

  const validation = (err, element, value) => {
    if (err) {
      document.getElementById(element).classList.add("border-danger");
      validationErr[element] = value;
      setErrmsg(validationErr);
    } else {
      document.getElementById(element).classList.remove("border-danger");
    }
  };

  const setdata = (e) => {
    const { name, value } = e.target;
    validation(false, e.target.id, "");
    setINP((preval) => {
      return {
        ...preval,
        [name]: value,
      };
    });
  };

  const [featuredfile, setFile] = useState({});
  const uploadFile = (e) => {
    setFile({
      /* contains the preview, if you want to show the picture to the user
           you can access it with this.state.currentPicture
       */
      filePreview: URL.createObjectURL(e.target.files[0]),
      /* this contains the file we want to send */
      fileAsFile: e.target.files[0],
    });
  };

  const setCareer = async (event) => {
    event.preventDefault();
    if (!inpval.name) {
      let element = "name";
      validation(true, element, "Enter name");
    }
    if (!inpval.email) {
      let element = "email";
      validation(true, element, "Enter email");
    }
    if (!inpval.message) {
      let element = "message";
      validation(true, element, "Enter message");
    }
    if (!inpval.name || !inpval.email || !inpval.message) {
      err = true;
    } else {
      err = false;
    }

    const formData = new FormData();
    formData.append("name", inpval.name);
    formData.append("email", inpval.email);
    formData.append("mobileno", "NA");
    formData.append("message", inpval.message);
    formData.append("file", featuredfile?.fileAsFile);
    formData.append("others", "");

    if (!err) {
      axios
        .post(`https://mail.intoggle.com/api/email`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          swal("Sent", "Your Query is Submitted", "success");
          setINP({
            name: "",
            email: "",
            message: "",
          });
          setFile({});
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  window.onload = setTimeout(function () {
    setActiveLoader({ loading: false, error: true });
  }, 2000);

  // ================================
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
      {activeLoader.loading == true ? (
        <>
          {/*====== Start Preloader ======*/}
          <div className="preloader">
            <div className="loader">
              <img src="assets/images/logo/loop.gif" />
              {/* <div className="pre-shadow" />
            <div className="pre-box" /> */}
            </div>
          </div>
          {/*====== End Preloader ======*/}
        </>
      ) : (
        <></>
      )}

      {/*====== Start Header Section ======*/}
      <Navbar />
      {/*====== End Header Section ======*/}
      {/* /assets/images/banner/aboutbanner.webp */}
      {/* <div id="carouselExamplInterval" className="carousel slide" data-bs-ride="carousel" data-bs-interval="5000" data-bs-pause="false"> */}
      <section className="home_carousel">
        <div
          id="carouselExampleIndicators3"
          className="carousel slide"
          data-ride="carousel"
        >
          {/* slides control */}
          {/* <ol className="carousel-indicators">
            <li
              data-target="#carouselExampleIndicators3"
              data-slide-to={0}
              className="active"
            />
            <li data-target="#carouselExampleIndicators3" data-slide-to={1} />
            <li data-target="#carouselExampleIndicators3" data-slide-to={2} />
            <li data-target="#carouselExampleIndicators3" data-slide-to={3} />
          </ol> */}
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="/assets/images/banner/1.webp"
                className="d-none d-lg-block  w-100"
                alt="..."
              />
              <img
                src="/assets/images/banner/12.webp"
                className="d-block d-lg-none w-100"
                alt="..."
              />
            </div>
            <div className="carousel-item">
              <img
                src="/assets/images/banner/2.webp"
                className="d-none d-lg-block  w-100"
                alt="..."
              />
              <img
                src="/assets/images/banner/13.webp"
                className="d-block d-lg-none w-100"
                alt="..."
              />
            </div>
            <div className="carousel-item">
              <img
                src="/assets/images/banner/8.webp"
                className="d-none d-lg-block w-100"
                alt="..."
              />
              <img
                src="/assets/images/banner/10.webp"
                className="d-block d-lg-none w-100"
                alt="..."
              />
            </div>
            <div className="carousel-item">
              <img
                src="/assets/images/banner/9.webp"
                className="d-none d-lg-block w-100"
                alt="..."
              />
              <img
                src="/assets/images/banner/11.webp"
                className="d-block d-lg-none w-100"
                alt="..."
              />
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-target="#carouselExampleIndicators3"
            data-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="sr-only">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-target="#carouselExampleIndicators3"
            data-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="sr-only">Next</span>
          </button>
        </div>
      </section>
      {/*====== Start About Section ======*/}
      <section className="about-area about-area-v1 position-relative pt-130">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="img-holder-box mb-50">
                <div className="img-holder wow fade-left ">
                  <img src="assets/images/about/about-11.webp" alt="Image" />
                </div>
                <div className="shape shape-one">
                  <span className="animate-float-y">
                    <img
                      src="assets/images/shape/we-are.png"
                      className="circle-logo"
                      alt="circle logo"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="text-wrapper mb-50 wow fade-right">
                <div className="section-title mb-15">
                  <span className="sub-title st-one">About Us</span>
                  <h2>Vision and Mission</h2>
                </div>
                <h4>Consultants in human resources…</h4>
                <p>
                  Our Vision lies in our philosophy to evolve ourselves as a
                  leader in expert Hunting & Recruitment solutions, globally
                  that meets every agreed expectation of our customers
                  instantaneously and economically. The way forward towards
                  achieving this goal is to provide innovative HR solutions that
                  take into account the unique needs of each client.
                </p>
                <ul className="list-style-one mb-35">
                  <li>HR Consulting…</li>
                  <li>Professional Problem…</li>
                  <li>Career solutions / Development Solution</li>
                </ul>
                <Link
                  to="/vissionandmission"
                  className="main-btn bordered-btn btn-blue arrow-btn"
                >
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*====== End About Section ======*/}
      {/*====== Start Service Section ======*/}
      <section className="service-area pt-90 pb-80 light-gray-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-title text-center mb-55 wow fade-up">
                <span className="sub-title st-one">Services</span>
                <h2>Consulting Solutions</h2>
                <p>
                  We are a global corporation offering staffing solutions,
                  Consulting and Outsourcing.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            {servicesdetails.map((service, index) => (
              <div key={index} className="col-lg-4 col-md-6 col-sm-12">
                <div
                  className="service-item service-style-one mb-40 wow fade-down"
                  data-wow-delay=".3s"
                >
                  <div className="icon">
                    <i className={service.icon} />
                  </div>
                  <div className="text">
                    <h3 className="title">
                      <Link key={service._id} to={`/service/${service.URL}`}>
                        {/* {service._id}. */}
                        {service.serviceName}
                      </Link>
                    </h3>
                    <ul className="list-style-two">
                      {service.features.map((feature, index) => (
                        <li key={index}>{feature}</li>
                      ))}
                    </ul>
                    <Link className="btn-link">
                      {" "}
                      <Link key={service._id} to={`/service/${service.URL}`}>
                        Read more
                      </Link>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="service-item service-style-one mb-40 wow fade-down"
                data-wow-delay=".3s"
              >
                <div className="icon">
                  <i className="flaticon-strategy" />
                </div>
                <div className="text">
                  <h3 className="title">
                    <Link href="/services">Permanent Staffing </Link>
                  </h3>
                  <ul className="list-style-two">
                    <li>Product Management</li>
                    <li>MVP Definition</li>
                    <li>Product Strategy</li>
                  </ul>
                  <Link href="/services" className="btn-link">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="service-item service-style-one mb-40 wow fade-down"
                data-wow-delay=".5s"
              >
                <div className="icon">
                  <i className="flaticon-design" />
                </div>
                <div className="text">
                  <h3 className="title">
                    <Link href="/services">Contract staffing </Link>
                  </h3>
                  <ul className="list-style-two">
                    <li>Product Management</li>
                    <li>MVP Definition</li>
                    <li>Product Strategy</li>
                  </ul>
                  <Link href="/services" className="btn-link">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="service-item service-style-one mb-40 wow fade-down"
                data-wow-delay=".7s"
              >
                <div className="icon">
                  <i className="flaticon-source-code" />
                </div>
                <div className="text">
                  <h3 className="title">
                    <Link href="/services">Executive search</Link>
                  </h3>
                  <ul className="list-style-two">
                    <li>Product Management</li>
                    <li>MVP Definition</li>
                    <li>Product Strategy</li>
                  </ul>
                  <Link href="/services" className="btn-link">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="service-item service-style-one mb-40 wow fade-down"
                data-wow-delay=".7s"
              >
                <div className="icon">
                  <i className="flaticon-source-code" />
                </div>
                <div className="text">
                  <h3 className="title">
                    <Link href="/services">END TO END HR SOLUTIONS</Link>
                  </h3>
                  <ul className="list-style-two">
                    <li>Product Management</li>
                    <li>MVP Definition</li>
                    <li>Product Strategy</li>
                  </ul>
                  <Link href="/services" className="btn-link">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="service-item service-style-one mb-40 wow fade-down"
                data-wow-delay=".7s"
              >
                <div className="icon">
                  <i className="flaticon-source-code" />
                </div>
                <div className="text">
                  <h3 className="title">
                    <Link href="/services">CAMPUS RECRUITMENT</Link>
                  </h3>
                  <ul className="list-style-two">
                    <li>Product Management</li>
                    <li>MVP Definition</li>
                    <li>Product Strategy</li>
                  </ul>
                  <Link href="/services" className="btn-link">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      {/*====== End Service Section ======*/}
      {/*====== Start Portfolio Section ======*/}
      <section className="portfolio-area portfolio-area-v1 light-gray-bg pt-130 pb-70 d-none">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="section-title mb-45 wow fade-up">
                <span className="sub-title st-one">Latest Work</span>
                <h2>Professional Experience</h2>
                {/* <p>Professional Design Agency to provide solutions</p> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="portfolio-filter-button mb-50 wow fade-left">
                <ul className="filter-btn mb-20">
                  <li data-filter="*" className="active">
                    Show All
                  </li>
                  <li data-filter=".cat-1">Design</li>
                  <li data-filter=".cat-2">Branding</li>
                  <li data-filter=".cat-3">Development</li>
                  <li data-filter=".cat-4">SEO</li>
                  <li data-filter=".cat-5">UX/UI Design</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row portfolio-grid">
            <div className="col-lg-4 col-md-6 col-sm-12 cat-1 portfolio-column cat-3">
              <div
                className="portfolio-item portfolio-style-one mb-55 wow fade-up"
                data-wow-delay=".1s"
              >
                <div className="img-holder">
                  <img src="assets/images/portfolio/img-1.jpg" alt="Img" />
                  <Link
                    to="assets/images/portfolio/img-1.jpg"
                    className="portfolio-hover img-popup"
                  >
                    <div className="hover-content">
                      <i className="far fa-plus" />
                    </div>
                  </Link>
                </div>
                <div className="text">
                  <h3 className="title">
                    <Link to="/portfoliodetails">Dashboard Design</Link>
                  </h3>
                  <Link href="/ourportfolio" className="cat-link">
                    Creative Design
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 portfolio-column cat-2 cat-4">
              <div
                className="portfolio-item portfolio-style-one mb-55 wow fade-up"
                data-wow-delay=".2s"
              >
                <div className="img-holder">
                  <img src="assets/images/portfolio/img-2.jpg" alt="Img" />
                  <Link
                    to="assets/images/portfolio/img-2.jpg"
                    className="portfolio-hover img-popup"
                  >
                    <div className="hover-content">
                      <i className="far fa-plus" />
                    </div>
                  </Link>
                </div>
                <div className="text">
                  <h3 className="title">
                    <Link to="/portfoliodetails">Landing Pages</Link>
                  </h3>
                  <Link to="/ourportfolio" className="cat-link">
                    Creative Design
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 portfolio-column cat-3 cat-4">
              <div
                className="portfolio-item portfolio-style-one mb-55 wow fade-up"
                data-wow-delay=".3s"
              >
                <div className="img-holder">
                  <img src="assets/images/portfolio/img-3.jpg" alt="Img" />
                  <Link
                    to="assets/images/portfolio/img-3.jpg"
                    className="portfolio-hover img-popup"
                  >
                    <div className="hover-content">
                      <i className="far fa-plus" />
                    </div>
                  </Link>
                </div>
                <div className="text">
                  <h3 className="title">
                    <Link to="/portfoliodetails">Illustration Design</Link>
                  </h3>
                  <Link to="/ourportfolio" className="cat-link">
                    Creative Design
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 portfolio-column cat-4 cat-3">
              <div
                className="portfolio-item portfolio-style-one mb-55 wow fade-up"
                data-wow-delay=".4s"
              >
                <div className="img-holder">
                  <img src="assets/images/portfolio/img-4.jpg" alt="Img" />
                  <Link
                    to="assets/images/portfolio/img-4.jpg"
                    className="portfolio-hover img-popup"
                  >
                    <div className="hover-content">
                      <i className="far fa-plus" />
                    </div>
                  </Link>
                </div>
                <div className="text">
                  <h3 className="title">
                    <Link to="/portfoliodetails">Dashboard Design</Link>
                  </h3>
                  <Link to="/ourportfolio" className="cat-link">
                    Creative Design
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 portfolio-column cat-5 cat-1">
              <div
                className="portfolio-item portfolio-style-one mb-55 wow fade-up"
                data-wow-delay=".5s"
              >
                <div className="img-holder">
                  <img src="assets/images/portfolio/img-5.jpg" alt="Img" />
                  <Link
                    to="assets/images/portfolio/img-5.jpg"
                    className="portfolio-hover img-popup"
                  >
                    <div className="hover-content">
                      <i className="far fa-plus" />
                    </div>
                  </Link>
                </div>
                <div className="text">
                  <h3 className="title">
                    <Link to="/portfoliodetails">Apps Development</Link>
                  </h3>
                  <Link to="/ourportfolio" className="cat-link">
                    Creative Design
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 portfolio-column cat-4 cat-2">
              <div
                className="portfolio-item portfolio-style-one mb-55 wow fade-up"
                data-wow-delay=".6s"
              >
                <div className="img-holder">
                  <img src="assets/images/portfolio/img-6.jpg" alt="Img" />
                  <Link
                    to="assets/images/portfolio/img-6.jpg"
                    className="portfolio-hover img-popup"
                  >
                    <div className="hover-content">
                      <i className="far fa-plus" />
                    </div>
                  </Link>
                </div>
                <div className="text">
                  <h3 className="title">
                    <Link to="/portfoliodetails">Website Design</Link>
                  </h3>
                  <Link to="/ourportfolio" className="cat-link">
                    Creative Design
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*====== End Portfolio Section ======*/}
      {/*====== Start CTA Section ======*/}
      <section className="cta-area cta-area-v1 pt-130">
        <div className="container-1450">
          <div className="cta-wrapper dark-blue-bg">
            <div className="cta-img wow fade-down ">
              <img src="assets/images/cta/img-1.jpg" alt />
            </div>
            <div className="row">
              <div className="col-xl-8 col-lg-12">
                <div className="text-wrapper wow fade-up">
                  <div className="section-title section-title-white mb-55">
                    <span className="sub-title st-one">Let’s Work</span>
                    <h2>
                      Experience &amp; Innovative{" "}
                      <span className="fill-text">Solutions </span> for{" "}
                      <span className="fill-text">Hunting</span> Candidates
                      &amp; Recruitment <span className="fill-text"></span>
                    </h2>
                  </div>
                  <Link
                    to="/contact"
                    className="main-btn bordered-btn btn-white arrow-btn"
                  >
                    Let's Work Together
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*====== End CTA Section ======*/}
      {/*====== Start Counter Section ======*/}
      <section className="counter-area counter-area-v1 pt-240 pb-90">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title text-center mb-55 wow fade-up">
                <span className="sub-title st-one">Agency Statistics</span>
                <h2>Why People’s Like Us</h2>
                {/* <p>Professional Design Agency to provide solutions</p> */}
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div
                className="counter-item mb-40 wow fade-up"
                data-wow-delay=".2s"
              >
                <div className="icon">
                  <i className="flaticon-start-up" />
                </div>
                <div className="text">
                  <h2 className="number">
                    <span>2500</span>+
                  </h2>
                  <p className="text-capitalise">Recruitment So Far</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div
                className="counter-item mb-40 wow fade-up"
                data-wow-delay=".3s"
              >
                <div className="icon">
                  <i className="flaticon-creativity" />
                </div>
                <div className="text">
                  <h2 className="number">
                    <span>30</span>+
                  </h2>
                  <p>Happy Clients</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div
                className="counter-item mb-40 wow fade-up"
                data-wow-delay=".4s"
              >
                <div className="icon">
                  <i className="flaticon-medal" />
                </div>
                <div className="text">
                  <h2 className="number">
                    <span>100</span>+
                  </h2>
                  <p>Projects Delivered</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div
                className="counter-item mb-40 wow fade-up"
                data-wow-delay=".5s"
              >
                <div className="icon">
                  <i className="flaticon-technical-support" />
                </div>
                <div className="text">
                  <h2 className="number">
                    <span>5</span>+
                  </h2>
                  <p>Years Of Experience</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*====== End Counter Section ======*/}
      {/*====== Start Testimonial Section ======*/}
      <section className="px-3">
        <section className="testimonial-area testimonial-area-v1 dark-blue-bg pattern-bg pt-50 pb-80 ">
          <div className="container">
            {/* tesimonial start */}
            <div className="pa-tesimonial spacer-top spacer-bottom">
              <div className="container">
                {/* <div className="pa-heading"> */}
                <div className="section-title section-title-white mb-55">
                  {/* <img src="assets/images/herbal.svg" alt="image" className="img-fluid" /> */}
                  <h2>What Our Client’s says About Us</h2>
                  <h5>feedback</h5>
                </div>
                {/* </div> */}
                <div className="row justify-content-center align-items-center ">
                  <div
                    className="swiper-container carousel slide"
                    id="carouselExampleControls1"
                    data-ride="carousel"
                  >
                    <div className="swiper-wrapper carousel-inner">
                      <div className="swiper-slide carousel-item active">
                        <div className="pa-tesimonial-box">
                          <div className="pas-testi-img">
                            <img
                              src="./assets/images/testimonial/5.webp"
                              alt="image"
                              className="img-fluid"
                            />
                          </div>
                          <div className="pas-testi-content">
                            <h2>Dr Anand Agrawal</h2>
                            <p>
                              Infinity Career Edge can align the job
                              requirements with the candidates' profiles in an
                              effective manner and has always been eager to
                              support the clients and the candidates. They are
                              professional and efficient in their approach help
                              us successfully recruit top talent . We wish
                              Infinity Career Edge best of luck and continued
                              success.
                            </p>
                            <footer class="blockquote-footer">
                              Vice Chancellor -{" "}
                              <cite title="Source Title">
                                CHANDIGARH UNIVERSITY{" "}
                              </cite>
                            </footer>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide carousel-item">
                        <div className="pa-tesimonial-box">
                          <div className="pas-testi-img">
                            <img
                              src="./assets/images/testimonial/4.webp"
                              alt="image"
                              className="img-fluid"
                            />
                          </div>
                          <div className="pas-testi-content">
                            <h2>Cindy Kennedy </h2>
                            <p>
                              Infinity Career Edge has consistently met the
                              needs of our organization. They proved to be most
                              effective and reliable business partner. We can
                              rely on Infinity Career Edge to deliver us right
                              person that will fit as per requirement.
                            </p>
                            <footer class="blockquote-footer">
                              Recruitment Head -{" "}
                              <cite title="Source Title"> Salesforce </cite>
                            </footer>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide carousel-item">
                        <div className="pa-tesimonial-box">
                          <div className="pas-testi-img">
                            <img
                              src="./assets/images/testimonial/3.webp"
                              alt="image"
                              className="img-fluid"
                            />
                          </div>
                          <div className="pas-testi-content">
                            <h2>Tapesh Kaushik </h2>
                            <p>
                              We would like to say thank for the quality of
                              service provided by Infinity Career Edge. We
                              sincerely appreciate your effort and the level of
                              detail & accountability you have demonstrated on
                              each project, and the way you conduct business as
                              a whole. We have, and will continue to, recommend
                              your services to other companies and contacts. Our
                              team could not be more satisfied with your work,
                              and we look forward to continuing this
                              relationship.
                            </p>
                            <footer class="blockquote-footer">
                              Hr Head -{" "}
                              <cite title="Source Title">Burger King </cite>
                            </footer>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide carousel-item">
                        <div className=" pa-tesimonial-box">
                          <div className="pas-testi-img">
                            <img
                              src="./assets/images/testimonial/1.webp"
                              alt="image"
                              className="img-fluid"
                            />
                          </div>
                          <div className="pas-testi-content">
                            <h2>Anisha Batra</h2>
                            <p>
                              Infinity Career Edge prompt attention, the
                              consistent follow up and the quality of candidates
                              we got speaks well of their recruiting and
                              screening process. Their work ethics and vast
                              knowledge levels of fitting the right candidates
                              to the position like a glove. We will look forward
                              to a continued working relationship.
                            </p>
                            <footer class="blockquote-footer">
                              {" "}
                              Hr Head - <cite title="Source Title">Etsy </cite>
                            </footer>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide carousel-item">
                        <div className="pa-tesimonial-box">
                          <div className="pas-testi-img">
                            <img
                              src="./assets/images/testimonial/2.webp"
                              alt="image"
                              className="img-fluid"
                            />
                          </div>
                          <div className="pas-testi-content">
                            <h2>Rajeev Kumar </h2>
                            <p>
                              Infinity Career Edge is dedicated to identifying
                              appropriate qualified individuals for our staffing
                              needs. The recruiters are dedicated to providing a
                              high degree of professionalism. We strongly
                              recommend Infinity Career Edge services best in
                              Industry .
                            </p>
                            <footer class="blockquote-footer">
                              Talent Acquisition Lead - &nbsp;
                              <cite title="Source Title">
                                eClerx Services Ltd
                              </cite>
                            </footer>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Add Arrows */}
                  </div>
                </div>
                <div className="pa-tesimonial-button">
                  <div
                    className="swiper-button-next carousel-control-next"
                    title="next"
                    type="button"
                    data-target="#carouselExampleControls1"
                    data-slide="next"
                  >
                    <span className="bg-dark p-2">
                      <i class="fas fa-angle-right"></i>
                    </span>
                  </div>
                  <div
                    className="swiper-button-prev carousel-control-prev"
                    type="button"
                    data-target="#carouselExampleControls1"
                    data-slide="prev"
                  >
                    <span className="bg-dark p-2">
                      <i class="fas fa-angle-left"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* tesimonial end */}
          </div>
        </section>
      </section>
      {/*====== End Testimonial Section ======*/}

      {/*====== Start Team Section ======*/}
      <section className="team-area team-area-v1  pt-130">
        <div className="container">
          <div className="row d-none justify-content-center">
            <div className="col-lg-8">
              <div className="section-title text-center mb-55 wow fadeInUp">
                <span className="sub-title st-one">Meet Our Team</span>
                <h2>Experience Team Members</h2>
                {/* <p>Professional Design Agency to provide solutions</p> */}
              </div>
            </div>
          </div>
          <div className="row d-none text-center">
            <div className="col-lg-3 col-md-3 col-sm-12 mx-auto">
              <div
                className="team-item mb-40 wow fadeInUp"
                data-wow-delay=".2s"
              >
                <div className="img-holder">
                  <img
                    src="assets/images/team/Prinkal Mukhi -  Founder.png"
                    alt="Team Image"
                  />
                </div>
                <div className="text text-center">
                  <h4 className="title">
                    <a href="team-details.html">Prinkal Mukhi</a>
                  </h4>
                  <p className="position">Founder</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-none ">
            <div className="col-lg-3 col-md-3 col-sm-12 offset-lg-3 offset-md-3">
              <div
                className="team-item mb-40 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div className="img-holder">
                  <img
                    src="assets/images/team/Kuldeep Thakur - Senior Manager - Human resource.webp"
                    alt="Team Image"
                  />
                  {/* <div className="team-hover">
                    <ul className="social-link">
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-dribbble" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </div>
                <div className="text text-center">
                  <h4 className="title">
                    <a href="team-details.html">Kuldeep Thakur</a>
                  </h4>
                  <p className="position">Senior Manager (HR)</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div
                className="team-item mb-40 wow fadeInUp"
                data-wow-delay=".4s"
              >
                <div className="img-holder">
                  <img
                    src="assets/images/team/Sumit Singh - Assitant Manage - Human resource.webp"
                    alt="Team Image"
                  />
                  {/* <div className="team-hover">
                    <ul className="social-link">
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-dribbble" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </div>
                <div className="text text-center">
                  <h4 className="title">
                    <a href="team-details.html">Sumit Singh</a>
                  </h4>
                  <p className="position">Assitant Manager (HR)</p>
                </div>
              </div>
            </div>
          </div>

          <div className="row d-none justify-content-center mb-5">
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div
                className="team-item mb-40 wow fadeInUp"
                data-wow-delay=".5s"
              >
                <div className="img-holder">
                  <img
                    src="assets/images/team/Niti - Manager -Human Resource.webp"
                    alt="Team Image"
                  />
                  {/* <div className="team-hover">
                    <ul className="social-link">
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-dribbble" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </div>
                <div className="text text-center">
                  <h4 className="title">
                    <a href="team-details.html">Niti </a>
                  </h4>
                  <p className="position">Manager (HR)</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div
                className="team-item mb-40 wow fadeInUp"
                data-wow-delay=".2s"
              >
                <div className="img-holder">
                  <img
                    src="assets/images/team/Arzoo Lalakiya -Manager -Human Resource.webp"
                    alt="Team Image"
                  />
                  {/* <div className="team-hover">
                    <ul className="social-link">
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-dribbble" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </div>
                <div className="text text-center">
                  <h4 className="title">
                    <a href="team-details.html">Arzoo Lalakiya</a>
                  </h4>
                  <p className="position">Manager (HR)</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div
                className="team-item mb-40 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div className="img-holder">
                  <img
                    src="assets/images/team/Vineetha Y -Manager -Human Resource.webp"
                    alt="Team Image"
                  />
                  {/* <div className="team-hover">
                    <ul className="social-link">
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-dribbble" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </div>
                <div className="text text-center">
                  <h4 className="title">
                    <a href="team-details.html">Vineetha </a>
                  </h4>
                  <p className="position">Manager (HR)</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div
                className="team-item mb-40 wow fadeInUp"
                data-wow-delay=".4s"
              >
                <div className="img-holder">
                  <img
                    src="assets/images/team/Preeti Sheoran -Manager -Human Resource.webp"
                    alt="Team Image"
                  />
                  {/* <div className="team-hover">
                    <ul className="social-link">
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-dribbble" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </div>
                <div className="text text-center">
                  <h4 className="title">
                    <a href="team-details.html">Preeti Sheoran </a>
                  </h4>
                  <p className="position">Manager (HR)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ====== End Team Section ====== */}

      <section className="client-locations position-relative pb-5">
        <div className="section-title text-center pt-5 pb-3 wow fadeInUp">
          <h2 className="text-white">Our Locations</h2>
          <p className="text-white col-md-8 mx-auto">
            We believe that the world is full of possibilities. We help you
            explore these possibilities and find new opportunities to make a
            difference.
          </p>
        </div>
        <div className="locations container mt-3 pt-md-0 pt-0">
          <div className="row ">
            <div className="col-lg-4 col-12 mb-md-0 mb-2">
              <div class="card boxshadow">
                <img
                  src="./assets/images/locations/1.webp"
                  class="card-img-top"
                  alt="..."
                />
                <div class="card-body text-center py-4">
                  <h5>
                    <b>Delhi, India</b>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12 mb-md-0 mb-2">
              <div class="card boxshadow">
                <img
                  src="./assets/images/locations/2.webp"
                  class="card-img-top"
                  alt="..."
                />
                <div class="card-body text-center py-4">
                  <h5>
                    <b>Chestermere, Canada</b>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12 mb-md-0 mb-2">
              <div class="card boxshadow">
                <img
                  src="./assets/images/locations/3.webp"
                  class="card-img-top"
                  alt="..."
                />
                <div class="card-body text-center py-4">
                  <h5>
                    <b>Himachal Pradesh, India</b>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*====== Start Contact Section ======*/}
      <section className="contact-area contact-area-v1 pt-70 pb-80 light-gray-bg">
        <div className="container mt-md-5 pt-md-5">
          <div className="row align-items-center pt-md-5">
            <div className="col-lg-8">
              <div className="text-wrapper mb-50 wow fade-left">
                <h2>
                  Have Any <span className="fill-text">Project in </span> Mind!
                  <span className="fill-text">Contact</span> Us
                </h2>
                <p>
                  {/* Sed ut perspiciatis unde omnis iste natus error voluptatem
                  accusantium doloremque laudan tium, totam rem aperiam, eaque
                  ipsa quae abillo <span>inventore veritatis</span> et quasi
                  architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam
                  voluptatem */}
                  Let us know your requirements and engage with us. We are sure
                  you will experience the joy of working with a partner who is
                  eager to contribute to your growth. Reach us today. We are
                  waiting to hear from you!
                </p>
                <div className="row">
                  {/* <div className="col-md-12 col-sm-12">
                    <span>
                      <i class="fa fa-map-marker" aria-hidden="true"></i>
                      Plot No 18 A, Block G&JU, Pitam Pura, Delhi, India 110088,
                      IN
                    </span>
                  </div> */}
                  {/* <div className="col-md-12 col-sm-12">
                    <span>
                      <i class="fa fa-map-marker" aria-hidden="true" />
                      Plot No 23/1 , Ward No 9, Near Zonal Hospital, Solan,
                      Himachal Pradesh 173212, IN
                    </span>
                  </div> */}
                  {/* <div className="col-md-12 col-sm-12">
                    <span>
                      <i class="fa fa-map-marker" aria-hidden="true" />
                      Kinniburgh Cres, Chestermere, Albreta T1X0M1, CA
                    </span>
                  </div> */}
                  <div className="col-md-6 col-sm-12">
                    <div className="information-style-one mb-40">
                      <div className="icon">
                        <span>
                          <i className="far fa-envelope-open" />
                          Email Us
                        </span>
                      </div>
                      <div className="info">
                        <h4>
                          <Link to="mailto:support@gmail.com">
                            hr@infinitycareeredge.com
                          </Link>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="information-style-one mb-40">
                      <div className="icon">
                        <span>
                          <i className="far fa-phone" />
                          Phone Us
                        </span>
                      </div>
                      <div className="info">
                        <h4>
                          <Link to="tel:+917018241327">+91- 701-824-1327</Link>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="contact-form">
                  <form>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form_group">
                          <input
                            type="text"
                            className="form_control"
                            placeholder="Full Name"
                            name="name"
                            id="name"
                            required
                            value={inpval.name}
                            onChange={setdata}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form_group">
                          <input
                            type="email"
                            className="form_control"
                            placeholder="Email Address"
                            name="email"
                            id="email"
                            required
                            value={inpval.email}
                            onChange={setdata}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form_group">
                          <textarea
                            className="form_control"
                            placeholder="Write Message"
                            name="message"
                            id="message"
                            defaultValue={""}
                            value={inpval.message}
                            onChange={setdata}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form_group">
                          <button
                            className="main-btn arrow-btn"
                            onClick={setCareer}
                          >
                            Send Us Message
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                {/* <p>
                  Our promise is simple: To treat each of our customers with
                  respect as we strive for exceptionally high standards with our
                  tailored services that leave lasting impressions – We stand
                  behind our promise.
                </p> */}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="img-holder mb-50 wow fade-right">
                <div className="shape shape-icon-one scene">
                  <span data-depth={1}>
                    <img src="assets/images/shape/shape-4.png" alt />
                  </span>
                </div>
                <div className="shape shape-icon-two">
                  <span />
                </div>
                <img
                  src="assets/images/contact/contact-1.jpg"
                  alt="Contact Image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*====== End Contact Section ======*/}

      {/*====== Start Blog Section ======*/}
      <section className="blog-area blog-area-v1 light-gray-bg pt-130 pb-130 d-none">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div
                className="section-title text-center mb-55 wow fade-up"
                data-wow-delay=".2s"
              >
                <span className="sub-title st-one">Articles News</span>
                <h2>Latest News &amp; Blogs</h2>
                <p>Professional Design Agency to provide solutions</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="blog-post-item mb-40 wow fade-up"
                data-wow-delay=".25s"
              >
                <div className="entry-content">
                  <Link to="#" className="cat-btn">
                    Design
                  </Link>
                  <h3 className="title">
                    <Link to="/blogdetails">
                      Everything You Want To Know About
                    </Link>
                  </h3>
                  <p>
                    Sit amet consectete adipising elit wedo eiusmod temeidiunt
                    laboret dolore magna ways
                  </p>
                  <Link to="/blogdetails" className="btn-link">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="blog-post-item mb-40 wow fade-up"
                data-wow-delay=".30s"
              >
                <div className="entry-content">
                  <Link to="#" className="cat-btn">
                    Design
                  </Link>
                  <h3 className="title">
                    <Link to="/blogdetails">
                      Designing Better Links For Websites And Emails
                    </Link>
                  </h3>
                  <p>
                    Sit amet consectete adipising elit wedo eiusmod temeidiunt
                    laboret dolore magna ways
                  </p>
                  <Link to="/blogdetails" className="btn-link">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="blog-post-item mb-40 wow fade-up"
                data-wow-delay=".35s"
              >
                <div className="entry-content">
                  <Link to="#" className="cat-btn">
                    Design
                  </Link>
                  <h3 className="title">
                    <Link to="/blogdetails">
                      Everything You Want To Know About
                    </Link>
                  </h3>
                  <p>
                    Sit amet consectete adipising elit wedo eiusmod temeidiunt
                    laboret dolore magna ways
                  </p>
                  <Link to="/blogdetails" className="btn-link">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="button-box text-center wow fade-up">
                <Link to="/blogs" className="main-btn arrow-btn">
                  View More News
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*====== End Blog Section ======*/}
      <Footer />
    </div>
  );
};

export default Home;
