import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../Navbar";
import Footer from "../Footer";
import servicesdetails from "./Servicecontent";
import { Link } from "react-router-dom";


const Servicedetail = () => {
  const { URL } = useParams(); // Get the ID from the URL
  const [currentService, setCurrentService] = useState("");

  // console.log(id);
  useEffect(() => {
      window.scrollTo(0, 0);
    // Find the service with the ID from the URL
    const serviceURL = window.location.pathname.split("/").pop(); // Get the ID from the URL
    const service = servicesdetails.find((s) => s.URL === serviceURL); // Find the service with the matching ID
    setCurrentService(service);
}, [URL]);

// console.log(currentService);

  return (
    <>
      <Navbar />
      {/*====== Start Page-banner section ======*/}
      <section className="page-banner bg_cover position-relative z-1">
        <div className="shape shape-one scene">
          <span data-depth={1}>
            <img src="/assets/images/shape/shape-1.png" alt />
          </span>
        </div>
        {/* <div className="shape shape-two scene">
          <span data-depth={2}>
            <img src="/assets/images/shape/shape-2.png" alt />
          </span>
        </div>
        <div className="shape shape-three scene">
          <span data-depth={3}>
            <img src="/assets/images/shape/shape-3.png" alt />
          </span>
        </div>
        <div className="shape shape-four scene">
          <span data-depth={4}>
            <img src="/assets/images/shape/shape-2.png" alt />
          </span>
        </div> */}
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="page-title">
                {/* title */}
                <h1>{currentService.serviceName}</h1>
                <ul className="breadcrumbs-link">
                  <li>
                    <Link href="/">Home</Link>
                  </li>
                  <li className="active">{currentService.serviceName}</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="page-banner-img">
                <img
                  src={currentService.serviceimage}
                  alt="service image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*====== End Page-banner section ======*/}
      {/*====== Start Features section ======*/}
      <section className="features-area  pt-210">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="text-wrapper mb-50 wow fadeInLeft">
                <div className="section-title mb-30">
                  <span className="sub-title st-one">Features</span>
                  {/* title */}
                  <h2>{currentService.serviceName}</h2>
                  {/* <p className="blue-dark">
                    Professional Design Agency to provide solutions
                  </p> */}
                </div>

                {/* short description */}
                <p>{currentService.shortdesc}</p>
                {/* <p>{currentService.tablecontent1}</p> */}
                {/* <p>{currentService.tablecontent2}</p> */}
                {/* <p>{currentService.tablecontent3}</p> */}

                {/* <Link href="/aboutus" className="main-btn">
                  Discover More
                </Link> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="features-list mb-50 wow fadeInRight">
                <div className="features-item mb-30">
                  <div className="text">
                    {/* table contents */}
                    <h4>{currentService.tableheading1}</h4>
                  </div>
                </div>
                <div className="features-item mb-30">
                  <div className="text">
                    <h4>{currentService.tableheading2}</h4>
                  </div>
                </div>
                {/* <div className="features-item mb-30">
                  <div className="text">
                    <h4>{currentService.tableheading3}</h4>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*====== End Features section ======*/}
      <section className="features-area light-gray-bg pt-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-wrapper mb-50 wow fadeInLeft">
                <div className="section-title mb-30">
                  {/* <span className="sub-title st-one">Features</span> */}
                  {/* <h2>service name</h2> */}

                  {/* sub heading */}
                  <p className="blue-dark">{currentService.subheading} </p>
                </div>
                {/* main content of the service */}
                <p>{currentService.Discription}</p>
                <Link to="/whoweare" className="main-btn">
                  Discover More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Servicedetail;
