import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import Navbar from "../Navbar";

const Whyus = () => {
  return (
    <div>
      <div>
        <Navbar />
        {/*====== Start Page-banner section ======*/}
        <section className="page-banner bg_cover  position-relative z-1">
          <div className="shape shape-one scene">
            <span data-depth={1}>
              <img src="assets/images/shape/shape-1.png" alt />
            </span>
          </div>
          <div className="shape shape-two scene">
            <span data-depth={2}>
              <img src="assets/images/shape/shape-2.png" alt />
            </span>
          </div>
          <div className="shape shape-three scene">
            <span data-depth={3}>
              <img src="assets/images/shape/shape-3.png" alt />
            </span>
          </div>
          <div className="shape shape-four scene">
            <span data-depth={4}>
              <img src="assets/images/shape/shape-2.png" alt />
            </span>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="page-title">
                  <h1>Why Us</h1>
                  <ul className="breadcrumbs-link">
                    <li>
                      <Link href="/">Home</Link>
                    </li>
                    <li className="active">Why Us</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="page-banner-img">
                  <img src="assets/images/about/hero-two-img-1.png" alt />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*====== End Page-banner section ======*/}
        {/*====== Start About section ======*/}
        <section
          className="about-area light-gray-bg about-area-v3 pt-190
        0 pb-0"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="text-wrapper mb-50 wow fadeInRight">
                  <div className="section-title mb-25">
                    <span className="sub-title st-one">Why Us</span>
                    <h2>The Top Provider Of Digital Solutions</h2>
                    <p className="blue-dark">
                    Solution-focused Consulting firm
                    </p>
                  </div>
                  <div className="wrapper-text">
                    Infinity Career Edge have redefined the process of hiring.
                    The ethos of our organization is based on delivering quality
                    through a result-oriented approach. We are driven by
                    creativity and innovation, having placed thousands of
                    professionals in various endeavours of their choice. We are
                    not only dedicated, but fully equipped with new-age tools
                    and technologies that give us a competitive edge. Our
                    customized solutions are tried and trusted by a number of
                    clients across several industries. Enthusiasm flows through
                    the hierarchy of our organization, with a team of highly
                    motivated consultants making the cream of talent hireable.
                  </div>
                  <ul className="list-style-one pt-5 mb-35">
                    <li>The top provider of digital solutions. </li>
                    <li>Solution-focused Consulting firm.</li>
                    {/* <li> Hire the most qualified individuals</li> */}
                    {/* <li> We work as partners</li>
                    <li>Quality is our benchmark</li>
                    <li>
                      Setting Our Clients up for sustainable HR Solutions –
                      Value that pays off!
                    </li> */}
                  </ul>
                  <Link
                    to="/whoweare"
                    className="main-btn bordered-btn btn-blue arrow-btn"
                  >
                    Learn More
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 overflow-hidden">
                <div className="img-holder mb-50 wow fadeInLeft">
                  <img src="assets/images/about/about-4.webp" alt />
                </div>
              </div>
            </div>
          </div>
        </section>{" "}
        <Footer />
      </div>
    </div>
  );
};

export default Whyus;
