// ========= Vission and mission page ==========================

import React, { useState, useEffect } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import Footer from "../Footer";
import Navbar from "../Navbar";
import { Link } from "react-router-dom";


const Vission = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <Navbar />
      <section className="page-banner bg_cover position-relative z-1">
        <div className="shape shape-one scene">
          <span data-depth={1}>
            <img src="assets/images/shape/shape-1.png" alt />
          </span>
        </div>
        {/* <div className="shape shape-two scene">
          <span data-depth={2}>
            <img src="assets/images/shape/shape-2.png" alt />
          </span>
        </div>
        <div className="shape shape-three scene">
          <span data-depth={3}>
            <img src="assets/images/shape/shape-3.png" alt />
          </span>
        </div>
        <div className="shape shape-four scene">
          <span data-depth={4}>
            <img src="assets/images/shape/shape-2.png" alt />
          </span>
        </div> */}
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="page-title">
                <h1>Vision and Mission</h1>
                <ul className="breadcrumbs-link">
                  <li>
                    <Link href="/">Home</Link>
                  </li>
                  <li className="active">Vision and Mission</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="page-banner-img">
                <img src="assets/images/breadcrumb/img-7.webp" alt />
                <div className="play-content" onClick={() => setIsOpen(true)}>
                  {/* <i className="playbtn fas fa-play " /> */}
                </div>
              </div>
              <ModalVideo
                channel="youtube"
                isOpen={isOpen}
                videoId="https://www.youtube.com/watch?v=AT6oSIDbGkw "
                onClose={() => setIsOpen(false)}
              />
            </div>
          </div>
        </div>
      </section>
      {/*====== End Page-banner section ======*/}

      {/*====== Start About Section ======*/}
      <section className="about-area about-area-v1 light-gray-bg position-relative pt-130">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="img-holder-box mb-50">
                <div className="img-holder wow fadeInLeft">
                  <img src="assets/images/about/img-4.jpg" alt="Image" />
                </div>
                <div className="shape shape-one">
                  <span className="animate-float-y">
                    <img
                      src="assets/images/shape/we-are.png"
                      className="circle-logo"
                      alt="circle logo"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="text-wrapper mb-50 wow fadeInRight">
                <div className="section-title mb-15">
                  {/* <span className="sub-title st-one">About Us</span> */}
                  <h2>Vision and Mission</h2>
                </div>
                <h4>Consultants in human resources…</h4>
                <div>
                  Our Vision lies in our philosophy to evolve ourselves as a
                  leader in expert Hunting & Recruitment solutions, globally
                  that meets every agreed expectation of our customers
                  instantaneously and economically. The way forward towards
                  achieving this goal is to provide innovative HR solutions that
                  take into account the unique needs of each client. 
                  <br></br>
                  Our mission is to be recognized as one of the most sought-after and
                  respected Talent Acquisition firms that provides seamless,
                  organized and customized services to the clients while
                  maintaining high values and decorum. We aim to be the go-to
                  organization for quick, high-quality and professional HR
                  solutions that help clients find talent that will thrive in
                  their organizations. Creating value for the society by various
                  ways remains our priority.
                </div>
                <ul className="list-style-one pt-5 mb-35">
                  <li>HR Consulting</li>
                  {/* <p></p> */}
                  <li>Professional Problem Solutions</li>
                  {/* <p></p> */}
                  <li>Career solutions &amp; Development</li>
                  {/* <p></p> */}
                </ul>
                <Link
                  to="/whoweare"
                  className="main-btn bordered-btn btn-blue arrow-btn"
                >
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*====== End About Section ======*/}

      <Footer />
    </div>
  );
};

export default Vission;